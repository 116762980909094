import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {DataGrid} from '@mui/x-data-grid';
import config from '../../config';
import {isTokenExpired} from '../Auth/LoginModal';
import SidebarDetails from "../../components/SidebarDetails";

import {
    IconButton,
    CircularProgress,
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    Drawer,
    Grid,
    Card,
    CardContent,
    Alert,
    Snackbar,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import {
    AccountCircle,
    Logout,
    MailOutline,
    Settings,
    Edit,
    HourglassEmpty,
    Delete,
    Lock,
    CheckCircle,
    Close as CloseIcon, Visibility, LockOpen,
} from '@mui/icons-material';

import './SideBar.css';
import './UserDashboard.css';
import FormProjet from '../Forms/FormProjet';
import FormTache from '../Forms/FormTache';
import FormTicket from '../Forms/FormTicket';
import FormSociete from '../Forms/FormSociete';
import FormClient from '../Forms/FormClient';
import ProfilModal from './ProfilModal';
import CheckIcon from '@mui/icons-material/Check';
import Sidebar from './SideBar';
import PropTypes from "prop-types";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ActionButtons from "../../components/ActionButtons";
import {useNavigate} from "react-router-dom";

// Composant pour la barre latérale (Sidebar)


// Composant principal
const UserDashboard = () => {
    // États
    const [isOpen, setIsOpen] = useState(true);
    const [projets, setProjets] = useState([]);
    const [taches, setTaches] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [activeData, setActiveData] = useState('projets');
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [formType, setFormType] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [dateTime, setDateTime] = useState(new Date());
    const [openProjets, setOpenProjets] = useState(false);
    const [openTaches, setOpenTaches] = useState(false);
    const [openTickets, setOpenTickets] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showProfileForm, setShowProfileForm] = useState(false);
    const [clientData, setClientData] = useState(null);
    const [role, setRole] = useState(sessionStorage.getItem('role'));
    const [openAdmin, setOpenAdmin] = useState(false);
    const [clients, setClients] = useState([]);
    const [societes, setSocietes] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // États pour la sidebar
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [editedTicket, setEditedTicket] = useState(null);
    const [editedTask, setEditedTask] = useState(null);
    const [editedProject, setEditedProject] = useState(null);
    const [maintenanceProjets, setMaintenanceProjets] = useState(false);
    const [maintenanceTaches, setMaintenanceTaches] = useState(false);
    const [maintenanceTickets, setMaintenanceTickets] = useState(false);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const fetchMaintenanceStatus = async () => {
            const token = localStorage.getItem('token') || sessionStorage.getItem('token');
            // Récupère le token JWT depuis le localStorage

            try {
                const [projetsMaintenance, tachesMaintenance, ticketsMaintenance] = await Promise.all([
                    axios.get(`${config.apiUrl}/api/configuration/projets/maintenance`, {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Ajoute le token JWT dans l'en-tête
                        },
                    }),
                    axios.get(`${config.apiUrl}/api/configuration/taches/maintenance`, {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Ajoute le token JWT dans l'en-tête
                        },
                    }),
                    axios.get(`${config.apiUrl}/api/configuration/tickets/maintenance`, {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Ajoute le token JWT dans l'en-tête
                        },
                    }),
                ]);

                // Mets à jour l'état de maintenance en fonction des données récupérées
                setMaintenanceProjets(projetsMaintenance.data);
                setMaintenanceTaches(tachesMaintenance.data);
                setMaintenanceTickets(ticketsMaintenance.data);

            } catch (error) {
                console.error('Erreur lors de la récupération des données de maintenance', error);
            }
        };

        // Appelle la fonction pour récupérer les états de maintenance au chargement de la page
        fetchMaintenanceStatus();
    }, []);  // Ce useEffect sera exécuté uniquement lors du montage du composant

    useEffect(() => {
        const currentRole = localStorage.getItem('role') || sessionStorage.getItem('role');
        setRole(currentRole);
        console.log("Rôle actuel chargé :", currentRole); // Pour vérifier le rôle chargé
    }, []);


    const renderCard = (title, itemCount, isMaintenance, onClick, cardDetails) => (
        <Card
            onClick={role === 'ROLE_ADMIN' || !isMaintenance ? onClick : null}
            sx={{
                cursor: role === 'ROLE_ADMIN' || !isMaintenance ? 'pointer' : 'not-allowed',
                backgroundColor: '#f4f6f9', // Keep the original white-ish card background
                borderRadius: '16px',
                border: isMaintenance ? '2px solid #ff9800' : '2px solid transparent', // Only border changes for maintenance
                boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.15)',
                position: 'relative', // For badge placement
                transition: 'box-shadow 0.3s ease',
                '&:hover': {
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
                },
            }}
        >
            {/* Badge for maintenance */}
            {isMaintenance && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        backgroundColor: '#ff9800',
                        color: 'white',
                        padding: '2px 8px',
                        borderRadius: '8px',
                        fontSize: '12px',
                    }}
                >
                    Maintenance
                </Box>
            )}

            <CardContent sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box textAlign="left">
                    <Typography variant="h6" fontWeight="bold" sx={{color: '#333'}}>
                        {title}
                    </Typography>
                    {cardDetails && (
                        <Box sx={{mt: 1}}>
                            <Typography variant="body2" color="textSecondary">
                                Ouverts: {cardDetails.ouverts}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Clôturés: {cardDetails.clotures}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Supprimés: {cardDetails.supprimes}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Total: {itemCount}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress
                        variant="determinate"
                        value={getProgressValue(itemCount)}
                        size={60}
                        thickness={5}
                        sx={{color: isMaintenance ? '#ff9800' : '#1976d2'}} // Orange for maintenance, Blue for normal
                    />
                    <Typography variant="h5" sx={{position: 'absolute', color: '#333', fontWeight: 'bold'}}>
                        {itemCount}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );


    // Fonctions utilitaires
    const formatStatus = (status) => {
        switch (status) {
            case 'EN_ATTENTE':
                return 'En attente';
            case 'PRIS_EN_COMPTE':
                return 'Pris en compte';
            case 'EN_COURS':
                return 'En cours';
            case 'TRAITE':
                return 'Traité';
            case 'CLOTURE':
                return 'Clôturé';
            case 'SUPPRIME':
                return 'Supprimé';
            default:
                return status;
        }
    };
    const handleToggleStatus = async (id, type, isCurrentlyActive) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        try {
            await axios.put(`${config.apiUrl}/api/${type}/${id}/toggle-status`, null, {
                headers: {Authorization: `Bearer ${token}`}, // Utilisation de backticks pour interpoler la variable token
            });
            setSnackbarMessage(`${type.slice(0, -1).toUpperCase()} ${isCurrentlyActive ? 'désactivé' : 'activé'} avec succès.`);
            setSnackbarSeverity('success');
            fetchData(); // Rafraîchir les données après mise à jour du statut
        } catch (error) {
            console.error(`Erreur lors de la mise à jour du statut pour ${type}`, error);
            setSnackbarMessage(`Erreur lors de la mise à jour du statut pour ${type}`);
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true); // Ouvrir la notification snackbar
    };


    const getProgressValue = (count) => (count > 0 ? Math.min((count / 10) * 100, 100) : 0);

    const MaintenanceButton = ({ section, isActive, setIsActive, label }) => (
        <Button
            onClick={() => toggleMaintenance(section, isActive, setIsActive)}
            variant="contained"
            startIcon={isActive ? <WarningIcon /> : <CheckCircleIcon />}  // Icône dynamique
            sx={{
                backgroundColor: isActive ? '#ff9800' : '#4caf50',   // Orange pour maintenance, vert pour pas de maintenance
                color: 'white',
                borderColor: isActive ? '#ff9800' : '#4caf50',       // Bordure orange ou verte
                '&:hover': {
                    backgroundColor: isActive ? '#e68a00' : '#388e3c',  // Couleur de survol
                },
            }}
        >
            {isActive ? `Désactiver Maintenance ${label}` : `Activer Maintenance ${label}`}
        </Button>
    );

    MaintenanceButton.propTypes = {
        section: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        setIsActive: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired,
    };

    // Gestion des états pour les sections
    const handleDrawerToggle = () => setIsOpen(!isOpen);


    // Gestion des formulaires
    const handleCreateClick = (type) => {
        setFormType(type);
        setIsFormVisible(true);
    };

    // Ouvrir la sidebar pour les tickets
    const handleModifyClick = async (row, type) => {
        // Réinitialiser les états des autres entités
        setEditedTicket(null);
        setEditedTask(null);
        setEditedProject(null);

        const isClient = sessionStorage.getItem('role') === 'ROLE_CLIENT';
        console.log("Rôle client :", isClient);

        switch (type) {
            case 'projet': {
                console.log("Projet sélectionné :", row);
                setSelectedProject(row);
                setEditedProject({ ...row, isReadOnly: isClient }); // Initialiser immédiatement avec les données actuelles

                try {
                    const projectDetails = await fetchProjectDetails(row.id); // Récupérer les détails depuis l'API
                    setEditedProject((prev) => ({
                        ...prev,
                        ...projectDetails,
                    })); // Compléter les détails après récupération
                    console.log("editedProject après récupération :", projectDetails);
                } catch (error) {
                    console.error("Erreur lors de la récupération des détails du projet :", error);
                }
                break;
            }

            case 'tache': {
                console.log("Tâche sélectionnée :", row);
                setSelectedTask(row);
                setEditedTask({ ...row, isReadOnly: isClient });

                try {
                    const taskDetails = await fetchTaskDetails(row.id);
                    setEditedTask((prev) => ({
                        ...prev,
                        ...taskDetails,
                    }));
                    console.log("editedTask après récupération :", taskDetails);
                } catch (error) {
                    console.error("Erreur lors de la récupération des détails de la tâche :", error);
                }
                break;
            }

            case 'ticket': {
                console.log("Ticket sélectionné :", row);
                setSelectedTicket(row);
                setEditedTicket({ ...row, isReadOnly: isClient });

                try {
                    const ticketDetails = await fetchTicketDetails(row.id);
                    setEditedTicket((prev) => ({
                        ...prev,
                        ...ticketDetails,
                    }));
                    console.log("editedTicket après récupération :", ticketDetails);
                } catch (error) {
                    console.error("Erreur lors de la récupération des détails du ticket :", error);
                }
                break;
            }

            default:
                console.error(`Type invalide pour handleModifyClick: ${type}`);
                return;
        }

        setIsSidebarOpen(true); // Ouvrir la sidebar
    };

    const handleCloseForm = () => {
        setIsFormVisible(false);
        setSelectedItem(null);
    };

    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);
        setSelectedTicket(null);
        setEditedTicket(null);
    };

    // Gestion des changements dans les champs de la sidebar
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (editedProject) {
            setEditedProject({ ...editedProject, [name]: value });
        } else if (editedTask) {
            setEditedTask({ ...editedTask, [name]: value });
        } else if (editedTicket) {
            setEditedTicket({ ...editedTicket, [name]: value });
        }
    };

    // Enregistrer les modifications du ticket, projet ou tâche
    const handleSaveChanges = async (type) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        let endpoint;
        let editedEntity;
        let setEntities;

        // Déterminer l'endpoint, l'entité éditée et la fonction de mise à jour appropriés
        if (type === 'ticket') {
            endpoint = `${config.apiUrl}/api/tickets/${editedTicket.id}`;
            editedEntity = editedTicket;
            setEntities = setTickets;
        } else if (type === 'tache') {
            endpoint = `${config.apiUrl}/api/taches/${editedTask.id}`;
            editedEntity = editedTask;
            setEntities = setTaches; // Assurez-vous que `setTaches` est défini dans votre état
        } else if (type === 'projet') {
            endpoint = `${config.apiUrl}/api/projets/${editedProject.id}`;
            editedEntity = editedProject;
            setEntities = setProjets; // Assurez-vous que `setProjets` est défini dans votre état
        }

        try {
            const response = await axios.put(endpoint, editedEntity, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200) {
                // Mise à jour de l'état local de l'entité correspondante
                setEntities((prevEntities) =>
                    prevEntities.map((entity) =>
                        entity.id === editedEntity.id ? editedEntity : entity
                    )
                );

                handleCloseSidebar(); // Fermer la sidebar après la sauvegarde
            }
        } catch (error) {
            console.error(`Erreur lors de la mise à jour de l'entité ${type}`, error);
        }
    };



    const handleStatusChange = async (id, newStatus, type) => {
        if (!id || !newStatus || !type) {
            console.error("Les paramètres 'id', 'newStatus', ou 'type' sont manquants.");
            return;
        }

        const urlMap = {
            'ticket': `${config.apiUrl}/api/tickets/${id}/status`,
            'tache': `${config.apiUrl}/api/taches/${id}/status`,
            'projet': `${config.apiUrl}/api/projets/${id}/status`
        };

        const url = urlMap[type];

        if (!url) {
            console.error(`Type d'objet invalide : ${type}`);
            return;
        }

        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        try {
            // Envoi de la requête sans corps si pas de rapport
            const response = await axios.put(url, {}, {
                params: {newStatus: newStatus},
                headers: {Authorization: `Bearer ${token}`}  // Ajout de backticks pour inclure le token
            });

            if (response.status === 200) {
                // Mise à jour immédiate de l'état local pour refléter le changement de statut
                if (type === 'ticket') {
                    setTickets((prevItems) =>
                        prevItems.map((ticket) =>
                            ticket.id === id ? {...ticket, statut: newStatus} : ticket
                        )
                    );
                } else if (type === 'tache') {
                    setTaches((prevItems) =>
                        prevItems.map((tache) =>
                            tache.id === id ? {...tache, statut: newStatus} : tache
                        )
                    );
                } else if (type === 'projet') {
                    setProjets((prevItems) =>
                        prevItems.map((projet) =>
                            projet.id === id ? {...projet, statut: newStatus} : projet
                        )
                    );
                }
                console.log(`Statut de ${type} mis à jour avec succès`);
            }
        } catch (error) {
            console.error(`Erreur lors de la mise à jour du statut pour ${type}`, error);
        }
    };


    const handleLogsClick = () => {
        setOpenAdmin(!openAdmin);
        setActiveData('logs'); // Met à jour la section active pour afficher les logs
    };


    const handleClotureClick = async (row) => {
        let apiUrl = '';
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');


        // Vérifie si le type de données est projet, tâche ou ticket et ajuste l'URL de l'API en conséquence
        if (activeData === 'projets') {
            apiUrl = `${config.apiUrl}/api/projets/${row.id}/cloture`;
        } else if (activeData === 'taches') {
            apiUrl = `${config.apiUrl}/api/taches/${row.id}/cloture`;
        } else if (activeData === 'tickets') {
            apiUrl = `${config.apiUrl}/api/tickets/${row.id}/cloture`;
        }

        try {
            const response = await axios.put(apiUrl, null, {
                headers: {
                    Authorization: `Bearer ${token}`,  // Utilisation de backticks pour interpoler le token
                },
            });

            console.log(`${activeData.slice(0, -1)} clôturé`, response.data);

            // Mettre à jour l'état local après la clôture
            if (activeData === 'projets') {
                setProjets((prevProjets) =>
                    prevProjets.map((projet) =>
                        projet.id === row.id
                            ? {...projet, statut: 'CLOTURE', dateCloture: new Date().toLocaleString('fr-FR')}
                            : projet
                    )
                );
            } else if (activeData === 'taches') {
                setTaches((prevTaches) =>
                    prevTaches.map((tache) =>
                        tache.id === row.id
                            ? {...tache, statut: 'CLOTURE', dateCloture: new Date().toLocaleString('fr-FR')}
                            : tache
                    )
                );
            } else if (activeData === 'tickets') {
                setTickets((prevTickets) =>
                    prevTickets.map((ticket) =>
                        ticket.id === row.id
                            ? {...ticket, statut: 'CLOTURE', dateFermeture: new Date().toLocaleString('fr-FR')}
                            : ticket
                    )
                );
            }
        } catch (error) {
            console.error(`Erreur lors de la clôture du ${activeData.slice(0, -1)}`, error);
        }
    };


    const fetchTicketDetails = async (ticketId) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        try {
            // Deux appels API : l'un pour les détails du ticket et l'autre pour l'historique des rapports
            const [ticketResponse, historiqueResponse] = await Promise.all([
                axios.get(`${config.apiUrl}/api/tickets/${ticketId}`, {
                    headers: {Authorization: `Bearer ${token}`},
                }),
                axios.get(`${config.apiUrl}/api/tickets/${ticketId}/historiqueRapports`, {
                    headers: {Authorization: `Bearer ${token}`},
                }),
            ]);

            if (ticketResponse.status === 200 && historiqueResponse.status === 200) {
                // Vérifie que l'historique des rapports est bien un tableau
                const historiqueRapports = Array.isArray(historiqueResponse.data) ? historiqueResponse.data : [];

                // Combine les données du ticket avec l'historique des rapports
                setEditedTicket({
                    ...ticketResponse.data, // Données du ticket (titre, description, etc.)
                    historiqueRapports,     // Historique des rapports
                });
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des détails du ticket', error);
        }
    };

    const fetchTaskDetails = async (taskId) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        try {
            const [taskResponse, historiqueResponse] = await Promise.all([
                axios.get(`${config.apiUrl}/api/taches/${taskId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get(`${config.apiUrl}/api/taches/${taskId}/historiqueRapports`, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
            ]);

            if (taskResponse.status === 200 && historiqueResponse.status === 200) {
                const historiqueRapports = Array.isArray(historiqueResponse.data) ? historiqueResponse.data : [];

                setEditedTask({
                    ...taskResponse.data, // Données de la tâche
                    historiqueRapports,   // Historique des rapports
                });
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de la tâche', error);
        }
    };

    const fetchProjectDetails = async (projectId) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        try {
            const [projectResponse, historiqueResponse] = await Promise.all([
                axios.get(`${config.apiUrl}/api/projets/${projectId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get(`${config.apiUrl}/api/projets/${projectId}/historiqueRapports`, {
                    headers: { Authorization: `Bearer ${token}` },
                }),
            ]);

            if (projectResponse.status === 200 && historiqueResponse.status === 200) {
                console.log("Détails du projet reçus :", projectResponse.data);
                const historiqueRapports = Array.isArray(historiqueResponse.data) ? historiqueResponse.data : [];

                const projectDetails = {
                    ...projectResponse.data,
                    historiqueRapports,
                };

                console.log("Données complètes à définir dans editedProject :", projectDetails);
                return projectDetails; // Assurez-vous que cette valeur est bien retournée
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des détails du projet :", error);
            throw error;
        }
    };


    const handleReportSubmit = async (newReport) => {
        const singularType = (() => {
            switch (activeData) {
                case 'projets': return 'projet';
                case 'taches': return 'tache';
                case 'tickets': return 'ticket';
                default: return activeData.slice(0, -1);
            }
        })();

        const editedItem = (() => {
            if (activeData === 'projets') return editedProject;
            if (activeData === 'taches') return editedTask;
            if (activeData === 'tickets') return editedTicket;
            return null;
        })();


        console.log("ActiveData :", activeData);
        console.log("État actuel :", {
            editedProject,
            editedTask,
            editedTicket,
            editedItem,
        });

        if (!editedItem || !editedItem.id) {
            console.error(`Erreur : Aucun ${singularType} sélectionné pour soumettre le rapport.`);
            return;
        }

        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        try {
            console.log("Appel API avec :", {
                url: `${config.apiUrl}/api/${singularType}s/${editedItem.id}/status`,
                body: { rapport: newReport },
            });

            const response = await axios.put(
                `${config.apiUrl}/api/${singularType}s/${editedItem.id}/status`,
                { rapport: newReport },
                {
                    params: { newStatus: 'TRAITE' },
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200) {
                console.log(`Rapport soumis pour ${singularType} avec ID ${editedItem.id}`);
                fetchData();
                handleCloseSidebar();
            }
        } catch (error) {
            console.error(`Erreur lors de la soumission du rapport pour ${singularType} :`, error);
        }
    };

// Ajoute ce useEffect pour surveiller les changements de isReportModalOpen
    useEffect(() => {
        console.log("isReportModalOpen updated:", isReportModalOpen);
    }, [isReportModalOpen]);

    const handleTreatedClick = (ticket) => {
        console.log("Bouton cliqué, ouverture du modal", ticket);
        setEditedTicket(ticket); // Assurer que le ticket est bien sélectionné
        setIsSidebarOpen(true); // S'assurer que la sidebar est fermée
    };


    const handleDeleteClick = async (row) => {
        let apiUrl = '';
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');


        // Vérifie le type de données actif et ajuste l'URL de l'API
        if (activeData === 'tickets') {
            apiUrl = `${config.apiUrl}/api/tickets/${row.id}/supprimer`;
        } else if (activeData === 'taches') {
            apiUrl = `${config.apiUrl}/api/taches/${row.id}/supprimer`;
        } else if (activeData === 'projets') {
            apiUrl = `${config.apiUrl}/api/projets/${row.id}/supprimer`;
        }

        try {
            const response = await axios.put(apiUrl, null, {
                headers: {Authorization: `Bearer ${token}`}, // Utilisation des backticks pour le token
            });

            if (response.status === 200) {
                // Mise à jour de l'état local après la suppression
                if (activeData === 'tickets') {
                    setTickets((prevTickets) =>
                        prevTickets.map((ticket) =>
                            ticket.id === row.id ? {...ticket, statut: 'SUPPRIME'} : ticket
                        )
                    );
                } else if (activeData === 'taches') {
                    setTaches((prevTaches) =>
                        prevTaches.map((tache) =>
                            tache.id === row.id ? {...tache, statut: 'SUPPRIME'} : tache
                        )
                    );
                } else if (activeData === 'projets') {
                    setProjets((prevProjets) =>
                        prevProjets.map((projet) =>
                            projet.id === row.id ? {...projet, statut: 'SUPPRIME'} : projet
                        )
                    );
                }
            }
        } catch (error) {
            console.error('Erreur lors de la suppression', error);
        }
    };


// Gestion du profil
    const handleProfileClick = () => setShowProfileForm(true);
    const handleCloseProfile = () => setShowProfileForm(false);

    const handleCardClick = (section, isMaintenance) => {
        if (role === 'ROLE_CLIENT' && isMaintenance) {
            alert(`La section ${section} est actuellement en maintenance. Veuillez réessayer plus tard.`);  // Utilisation des backticks pour interpoler
        } else {
            setActiveData(section);
        }
    };


// Déconnexion
    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('role');
        window.location.href = '/';
    };

// Gestion des données

    const fetchData = async () => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        if (!token) {
            console.error("Aucun token JWT trouvé.");
            setSnackbarMessage('Erreur : Aucun token JWT trouvé.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        try {
            // Appels API parallèles avec gestion des résultats partiels
            const [
                projetsResult,
                tachesResult,
                ticketsResult,
                clientsResult,
                societesResult,
                logsResult,
            ] = await Promise.allSettled([
                axios.get(`${config.apiUrl}/api/projets`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${config.apiUrl}/api/taches`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${config.apiUrl}/api/tickets`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${config.apiUrl}/api/client`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${config.apiUrl}/api/societes`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${config.apiUrl}/api/logs/connexion`, { headers: { Authorization: `Bearer ${token}` } }),
            ]);

            // Mise à jour des états pour chaque type de données
            if (projetsResult.status === 'fulfilled') {
                setProjets(Array.isArray(projetsResult.value.data) ? projetsResult.value.data : []);
            } else {
                console.error('Erreur lors de la récupération des projets:', projetsResult.reason);
            }

            if (tachesResult.status === 'fulfilled') {
                setTaches(Array.isArray(tachesResult.value.data) ? tachesResult.value.data : []);
            } else {
                console.error('Erreur lors de la récupération des tâches:', tachesResult.reason);
            }

            if (ticketsResult.status === 'fulfilled') {
                const clientsMapping = clientsResult.status === 'fulfilled'
                    ? clientsResult.value.data.reduce((map, client) => ({ ...map, [client.id]: client.nom }), {})
                    : {};
                const ticketsWithCreatorName = ticketsResult.value.data.map(ticket => ({
                    ...ticket,
                    creator_name: clientsMapping[ticket.client_id] || 'Nom non disponible',
                }));
                setTickets(ticketsWithCreatorName);
            } else {
                console.error('Erreur lors de la récupération des tickets:', ticketsResult.reason);
            }

            if (clientsResult.status === 'fulfilled') {
                setClients(Array.isArray(clientsResult.value.data) ? clientsResult.value.data : []);
            } else {
                console.error('Erreur lors de la récupération des clients:', clientsResult.reason);
            }

            if (societesResult.status === 'fulfilled') {
                setSocietes(Array.isArray(societesResult.value.data) ? societesResult.value.data : []);
            } else {
                console.error('Erreur lors de la récupération des sociétés:', societesResult.reason);
            }

            if (logsResult.status === 'fulfilled') {
                setLogs(Array.isArray(logsResult.value.data) ? logsResult.value.data : []);
            } else {
                console.error('Erreur lors de la récupération des logs:', logsResult.reason);
            }
        } catch (error) {
            console.error('Erreur générale lors de la récupération des données:', error);
            setSnackbarMessage('Erreur générale lors de la récupération des données.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };




    useEffect(() => {
        fetchData();

        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        if (isTokenExpired(token)) {
            console.log("Le token est expiré");
        } else {
            console.log("Le token est valide");
        }

        // Charger les informations du client connecté
        axios
            .get(`${config.apiUrl}/api/client/profile`, {
                headers: {Authorization: `Bearer ${token}`}, // Utilisation des backticks pour le token
            })
            .then((response) => {
                setClientData(response.data);
            })
            .catch((error) => {
                console.error('Erreur lors de la récupération du profil du client', error);
            });

        // Mettre à jour l'heure en temps réel
        const timer = setInterval(() => setDateTime(new Date()), 1000);
        return () => clearInterval(timer);
    }, []);


    const toggleMaintenance = async (section, currentState, setState) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        try {
            await axios.put(`${config.apiUrl}/api/configuration/${section}/maintenance`, {
                enMaintenance: !currentState
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setState(!currentState);
            setSnackbarMessage(`${section.charAt(0).toUpperCase() + section.slice(1)} maintenance ${currentState ? "désactivée" : "activée"}`);
            setSnackbarSeverity('success');
        } catch (error) {
            console.error(`Erreur lors de la mise à jour de la maintenance pour ${section}`, error);
            setSnackbarMessage(`Erreur lors de la modification de la maintenance pour ${section}`);
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true); // Utilise snackbarOpen au lieu de openAlert
    };

    const columnsLogs = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'dateHeure', headerName: 'Date & Heure', width: 180 },
        { field: 'operateur', headerName: 'Opérateur', width: 150 },
        { field: 'action', headerName: 'Action', width: 120 },
        { field: 'poste', headerName: 'Poste', width: 150 },
        { field: 'ip', headerName: 'IP', width: 150 },
    ];

    const columnsProjets = [
        { field: 'numeroProjet', headerName: 'ID Projet', flex: 1 },
        { field: 'nom', headerName: 'Nom', width: 150, flex: 1 },
        { field: 'description', headerName: 'Description', width: 300, flex: 2 },
        {
            field: 'statut',
            headerName: 'Statut',
            flex: 1,
            renderCell: (params) => (
                <span>{formatStatus(params.row.statut)}</span>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                const role = localStorage.getItem('role') || sessionStorage.getItem('role');

                const handleActions = {
                    view: (projet) => handleModifyClick(projet, 'projet'),
                    changeStatus: (id, status) => handleStatusChange(id, status, 'projet'),
                    treat: (projet) => handleTreatedClick(projet),
                    cloture: (projet) => handleClotureClick(projet),
                    delete: (projet) => handleDeleteClick(projet),
                };

                return (
                    <ActionButtons
                        params={params}
                        role={role}
                        handleActions={handleActions}
                    />
                );
            },
        },
    ];


    const columnsTaches = [
        { field: 'numeroTache', headerName: 'ID Tâche', flex: 1 },
        { field: 'nom', headerName: 'Nom', width: 150, flex: 1 },
        { field: 'description', headerName: 'Description', width: 300, flex: 2 },
        {
            field: 'statut',
            headerName: 'Statut',
            flex: 1,
            renderCell: (params) => (
                <span>{formatStatus(params.row.statut)}</span>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                const role = localStorage.getItem('role') || sessionStorage.getItem('role');

                const handleActions = {
                    view: (tache) => handleModifyClick(tache, 'tache'),
                    changeStatus: (id, status) => handleStatusChange(id, status, 'tache'),
                    treat: (tache) => handleTreatedClick(tache),
                    cloture: (tache) => handleClotureClick(tache),
                    delete: (tache) => handleDeleteClick(tache),
                };

                return (
                    <ActionButtons
                        params={params}
                        role={role}
                        handleActions={handleActions}
                    />
                );
            },
        },
    ];

    const clientId = parseInt(localStorage.getItem('clientId') || sessionStorage.getItem('clientId'), 10); // Récupère l'ID de l'utilisateur connecté

    const columnsTickets = [
        { field: 'numeroTicket', headerName: 'ID Ticket', flex: 1 },
        { field: 'dateOuverture', headerName: 'Date de Création', width: 150, flex: 1 },
        { field: 'clientNom', headerName: 'Créateur', width: 150, flex: 1 },
        { field: 'titre', headerName: 'Titre', width: 150, flex: 1 },
        { field: 'description', headerName: 'Description', width: 300, flex: 2 },
        {
            field: 'statut',
            headerName: 'Statut',
            flex: 0.5,
            renderCell: (params) => (
                <span>{formatStatus(params.row.statut)}</span>
            )
        },
        { field: 'dateFermeture', headerName: 'Date de Clôture', width: 150, flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => {
                // Définir les fonctions de gestion des actions
                const handleActions = {
                    view: (ticket) => handleModifyClick(ticket, 'ticket'),
                    changeStatus: (id, status) => handleStatusChange(id, status, 'ticket'),
                    treat: (ticket) => handleTreatedClick(ticket),
                    cloture: (ticket) => handleClotureClick(ticket),
                    delete: (ticket) => handleDeleteClick(ticket),
                };

                const role = localStorage.getItem('role') || sessionStorage.getItem('role');

                return (
                    <ActionButtons
                        params={params}
                        role={role}
                        handleActions={handleActions}
                    />
                );
            },
        }
    ];


// Colonnes pour les clients
    const columnsClients = [
        {field: 'id', headerName: 'ID Client', width: 150},
        {field: 'nom', headerName: 'Nom', width: 200},
        {field: 'email', headerName: 'Email', width: 300},
        {
            field: 'statut',
            headerName: 'Statut',
            width: 200,
            renderCell: (params) => {
                return role === 'ROLE_ADMIN' ? (
                    <Button
                        variant="contained"
                        color={params.row.statut === 'ACTIF' ? 'secondary' : 'primary'}
                        startIcon={params.row.statut === 'ACTIF' ? <Lock /> : <LockOpen />}
                        onClick={() => handleToggleStatus(params.row.id, 'client', params.row.statut === 'ACTIF')}
                    >
                        {params.row.statut === 'ACTIF' ? 'Désactiver' : 'Activer'}
                    </Button>
                ) : (
                    <span>{params.row.statut}</span>
                );
            }
        }
    ];

// Colonnes pour les sociétés
    const columnsSocietes = [
        {field: 'id', headerName: 'ID Société', width: 150},
        {field: 'nom', headerName: 'Nom', width: 200},
        {field: 'adresse', headerName: 'Adresse', width: 300},
        {
            field: 'statut',
            headerName: 'Statut',
            width: 200,
            renderCell: (params) => {
                return role === 'ROLE_ADMIN' ? (
                    <Button
                        variant="contained"
                        color={params.row.statut === 'ACTIF' ? 'secondary' : 'primary'}
                        startIcon={params.row.statut === 'ACTIF' ? <Lock /> : <LockOpen />}
                        onClick={() => handleToggleStatus(params.row.id, 'societes', params.row.statut === 'ACTIF')}
                    >
                        {params.row.statut === 'ACTIF' ? 'Désactiver' : 'Activer'}
                    </Button>
                ) : (
                    <span>{params.row.statut}</span>
                );
            }
        }
    ];


// Fonctions pour obtenir les colonnes actives
    const getActiveColumns = () => {
        switch (activeData) {
            case 'taches':
                return columnsTaches;
            case 'tickets':
                return columnsTickets;
            case 'clients':
                return columnsClients;
            case 'societes':
                return columnsSocietes;
            case 'logs':
                return columnsLogs; // Colonnes pour les logs de connexion
            default:
                return columnsProjets;
        }
    };


    const getActiveRows = () => {
        switch (activeData) {
            case 'projets':
                return projets;
            case 'taches':
                return taches;
            case 'tickets':
                return tickets;
            case 'clients':
                return clients;
            case 'societes':
                return societes;
            case 'logs':
                return logs; // Données pour les logs de connexion
            default:
                return [];
        }
    };

    useEffect(() => {
        // Vérifiez et redirigez si la section active est en maintenance pour les clients
        if (role === 'ROLE_CLIENT') {
            if (maintenanceProjets && activeData === 'projets') {
                alert("La section Projets est actuellement en maintenance. Vous allez être redirigé.");
                setActiveData('taches'); // Redirige vers une section non en maintenance
            } else if (maintenanceTaches && activeData === 'taches') {
                alert("La section Tâches est actuellement en maintenance. Vous allez être redirigé.");
                setActiveData('tickets'); // Redirige vers une section non en maintenance
            } else if (maintenanceTickets && activeData === 'tickets') {
                alert("La section Tickets est actuellement en maintenance. Vous allez être redirigé.");
                setActiveData('clients'); // Redirige vers une section non en maintenance
            }
        }
    }, [activeData, maintenanceProjets, maintenanceTaches, maintenanceTickets, role]);

    const navigate = useNavigate();

    return (
        <Box sx={{display: 'flex', height: '100vh'}}>
            {/* Barre latérale */}
            <Sidebar
                isOpen={isOpen}
                handleDrawerToggle={handleDrawerToggle}
                openProjets={activeData === 'projets'}
                handleProjetsClick={() => {
                    if (role !== 'ROLE_CLIENT' || !maintenanceProjets) {
                        setActiveData('projets');
                    } else {
                        alert("La section Projets est actuellement en maintenance.");
                    }
                }}
                openTaches={activeData === 'taches'}
                handleTachesClick={() => {
                    if (role !== 'ROLE_CLIENT' || !maintenanceTaches) {
                        setActiveData('taches');
                    } else {
                        alert("La section Tâches est actuellement en maintenance.");
                    }
                }}
                openTickets={activeData === 'tickets'}
                handleTicketsClick={() => {
                    if (role !== 'ROLE_CLIENT' || !maintenanceTickets) {
                        setActiveData('tickets');
                    } else {
                        alert("La section Tickets est actuellement en maintenance.");
                    }
                }}
                openClients={activeData === 'clients'}
                handleClientsClick={() => setActiveData('clients')}
                openSocietes={activeData === 'societes'}
                handleSocietesClick={() => setActiveData('societes')}
                role={role}
                handleCreateClick={handleCreateClick}
                setActiveData={setActiveData}
                handleLogsClick={handleLogsClick} // Ajoutez ceci
                maintenanceProjets={maintenanceProjets}
                maintenanceTaches={maintenanceTaches}
                maintenanceTickets={maintenanceTickets}
            />


            {/* Contenu principal */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    padding: 3,
                    transition: 'width 0.3s ease',
                    width: `calc(100% - ${isOpen ? '240px' : '60px'})`,  // Utilisation des backticks pour interpoler la valeur
                }}
            >

                {/* En-tête */}
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3}}>
                    <Typography variant="h4" component="h1">
                        Dashboard
                    </Typography>

                    {role === 'ROLE_ADMIN' && (
                        <>
                            <MaintenanceButton
                                section="projets"
                                isActive={maintenanceProjets}
                                setIsActive={setMaintenanceProjets}
                                label="Projets"
                            />

                            <MaintenanceButton
                                section="taches"
                                isActive={maintenanceTaches}
                                setIsActive={setMaintenanceTaches}
                                label="Tâches"
                            />

                            <MaintenanceButton
                                section="tickets"
                                isActive={maintenanceTickets}
                                setIsActive={setMaintenanceTickets}
                                label="Tickets"
                            />

                        </>
                    )}


                    {/* Notifications et actions utilisateur */}
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant="body1" sx={{mr: 2}}>
                            {dateTime.toLocaleDateString()} {dateTime.toLocaleTimeString()}
                        </Typography>
                        <IconButton onClick={() => navigate('/profile')}>
                            <AccountCircle/>
                        </IconButton>
                        <IconButton>
                            <MailOutline/>
                        </IconButton>
                        <IconButton>
                            <Settings/>
                        </IconButton>
                        <IconButton onClick={handleLogout}>
                            <Logout/>
                        </IconButton>
                    </Box>
                </Box>

                {/* Cartes de statistiques */}
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        {renderCard('Projets', projets.length, maintenanceProjets, () => {
                            // Autoriser le clic si le rôle est admin ou si la section n'est pas en maintenance
                            if (role === 'ROLE_ADMIN' || !maintenanceProjets) {
                                setActiveData('projets');
                            }
                        }, {
                            ouverts: projets.filter((t) => t.statut !== 'CLOTURE' && t.statut !== 'SUPPRIME').length,
                            clotures: projets.filter((t) => t.statut === 'CLOTURE').length,
                            supprimes: projets.filter((t) => t.statut === 'SUPPRIME').length,
                        })}
                    </Grid>
                    <Grid item xs={4}>
                        {renderCard('Tâches', taches.length, maintenanceTaches, () => {
                            // Autoriser le clic si le rôle est admin ou si la section n'est pas en maintenance
                            if (role === 'ROLE_ADMIN' || !maintenanceTaches) {
                                setActiveData('taches');
                            }
                        }, {
                            ouverts: taches.filter((t) => t.statut !== 'CLOTURE' && t.statut !== 'SUPPRIME').length,
                            clotures: taches.filter((t) => t.statut === 'CLOTURE').length,
                            supprimes: taches.filter((t) => t.statut === 'SUPPRIME').length,
                        })}
                    </Grid>
                    <Grid item xs={4}>
                        {renderCard('Tickets', tickets.length, maintenanceTickets, () => {
                            // Autoriser le clic si le rôle est admin ou si la section n'est pas en maintenance
                            if (role === 'ROLE_ADMIN' || !maintenanceTickets) {
                                setActiveData('tickets');
                            }
                        }, {
                            ouverts: tickets.filter((t) => t.statut !== 'CLOTURE' && t.statut !== 'SUPPRIME').length,
                            clotures: tickets.filter((t) => t.statut === 'CLOTURE').length,
                            supprimes: tickets.filter((t) => t.statut === 'SUPPRIME').length,
                        })}
                    </Grid>
                </Grid>


                {/* Tableau des données */}
                <Box sx={{flexGrow: 1, mt: 3}}>
                    {isFormVisible ? (
                        // Affichage des formulaires
                        <>
                            {formType === 'projets' && (
                                <FormProjet
                                    title={selectedItem ? 'Modifier le Projet' : 'Créer un Projet'}
                                    onClose={handleCloseForm}
                                    projetData={selectedItem}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            {formType === 'taches' && (
                                <FormTache
                                    title={selectedItem ? 'Modifier la Tâche' : 'Créer une Tâche'}
                                    onClose={handleCloseForm}
                                    tacheData={selectedItem}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            {formType === 'tickets' && (
                                <FormTicket
                                    title={selectedItem ? 'Modifier le Ticket' : 'Créer un Ticket'}
                                    onClose={handleCloseForm}
                                    ticketData={selectedItem}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            {formType === 'creer-societe' && (
                                <FormSociete
                                    title="Créer une Société"
                                    onClose={handleCloseForm}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                            {formType === 'creer-client' && (
                                <FormClient
                                    title="Créer un Client"
                                    onClose={handleCloseForm}
                                    onSuccess={() => {
                                        fetchData();
                                        setIsFormVisible(false);
                                    }}
                                />
                            )}
                        </>
                    ) : (

                        // Affichage du tableau de données
                        <div style={{height: 400, width: '100%'}}>
                            <DataGrid
                                rows={getActiveRows().map((row) => ({
                                    ...row,
                                    className: row.statut === 'SUPPRIME' ? 'deleted-row' : '',
                                }))}
                                columns={getActiveColumns()}
                                pageSize={10}
                                rowsPerPageOptions={[5, 10, 20]}
                                checkboxSelection
                                disableSelectionOnClick
                                sortingOrder={['desc', 'asc']}
                                initialState={{
                                    sorting: {
                                        sortModel: activeData === 'tickets'
                                            ? [{field: 'dateOuverture', sort: 'desc'}]
                                            : activeData === 'projets' || activeData === 'taches'
                                                ? [{field: 'dateDebut', sort: 'desc'}]
                                                : [],
                                    },
                                }}
                                getRowClassName={(params) => {
                                    if (params.row.statut) {
                                        if (params.row.statut === 'SUPPRIME') return 'deleted-row';
                                        if (params.row.statut === 'Nouveau') return 'new-row';
                                    }
                                    return '';
                                }}
                                columnBuffer={5}
                                autoHeight
                                sx={{
                                    '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#f5f5f5', // Couleur de l'en-tête des colonnes
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'nowrap', // Empêche le retour à la ligne dans les cellules
                                    },
                                    '& .deleted-row': {
                                        backgroundColor: '#d3d3d3', // Couleur des lignes supprimées
                                        textDecoration: 'line-through', // Ligne barrée pour indiquer la suppression
                                        pointerEvents: 'none', // Désactive les interactions avec les lignes supprimées
                                        opacity: 0.5, // Rendre la ligne plus pâle pour indiquer qu'elle est supprimée
                                    },
                                    '& .new-row': {
                                        backgroundColor: '#e0ffe0', // Couleur des lignes nouvellement créées
                                    },
                                }}
                            />
                        </div>
                    )}
                </Box>
                {/* <Box
                    sx={{
                        textAlign: 'right',
                        padding: 3,
                        backgroundColor: '#f5f5f5',
                        position: 'relative', // Changez la position à 'relative'
                        marginTop: 'auto', // Ajoutez un espace pour pousser le footer en bas après le contenu
                        width: '100%',
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Lou-Ann Technologies © 2024 - Beta
                    </Typography>
                </Box>*/}
            </Box>

            {/* Snackbar pour les notifications */}
            <Snackbar
                open={snackbarOpen}  // Utilise snackbarOpen
                autoHideDuration={4000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity={snackbarSeverity}
                    onClose={() => setSnackbarOpen(false)}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <SidebarDetails
                isOpen={isSidebarOpen}
                onClose={handleCloseSidebar}
                editedItem={editedTask || editedProject || editedTicket}
                role={role}
                type={activeData.slice(0, -1)} // Convertit 'projets' -> 'projet', 'taches' -> 'tache', etc.
                handleInputChange={handleInputChange}
                handleSaveChanges={handleSaveChanges}
                handleReportSubmit={handleReportSubmit}
            />
        </Box>
    );
};


export default UserDashboard;