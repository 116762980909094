import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, MenuItem, Paper, Stack, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import config from "../../config";

const FormTache = ({ title, onClose, onSuccess }) => {
    const [nom, setNom] = useState('');
    const [description, setDescription] = useState('');
    const [projetId, setProjetId] = useState('');
    const [projets, setProjets] = useState([]);
    const [dateDebut, setDateDebut] = useState('');
    const [dateFin, setDateFin] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const loadProjets = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await axios.get(`${config.apiUrl}/api/projets`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setProjets(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Erreur lors du chargement des projets', error);
                setProjets([]);
            }
        };

        loadProjets();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setOpenConfirm(true);
    };

    const handleConfirmSubmit = async () => {
        try {
            const token = sessionStorage.getItem('token');
            const response = await axios.post(`${config.apiUrl}/api/taches`, {
                nom,
                description,
                projet: projetId ? { id: projetId } : null,
                dateDebut,
                dateFin,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status >= 200 && response.status < 300) {
                setSnackbarMessage('Tâche créée avec succès.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setTimeout(() => {
                    onClose(); // Appeler onClose après un délai
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                }, 1000); // Attendre un peu pour que le snackbar s'affiche
            }
        } catch (error) {
            console.error('Erreur lors de la création de la tâche', error);
            setErrorMessage('Erreur lors de la création de la tâche');
            setSnackbarMessage('Erreur lors de la création de la tâche.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
        setOpenConfirm(false);
    };

    const handleCancel = () => {
        setOpenConfirm(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 500, margin: 'auto', mt: 5 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {title || 'Créer une Tâche'}
                </Typography>
                {errorMessage && (
                    <Typography variant="body1" color="error" gutterBottom>
                        {errorMessage}
                    </Typography>
                )}
                <TextField
                    label="Nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                />
                <TextField
                    select
                    label="Projet"
                    value={projetId || ''}
                    onChange={(e) => setProjetId(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    helperText="Sélectionner un projet associé"
                >
                    <MenuItem value="">Aucun projet</MenuItem>
                    {Array.isArray(projets) && projets.length > 0 ? (
                        projets.map((projet) => (
                            <MenuItem key={projet.id} value={projet.id}>
                                {projet.nom}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Aucun projet disponible</MenuItem>
                    )}
                </TextField>

                <TextField
                    label="Date de début"
                    type="date"
                    value={dateDebut}
                    onChange={(e) => setDateDebut(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Date de fin"
                    type="date"
                    value={dateFin}
                    onChange={(e) => setDateFin(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        Annuler
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        Créer
                    </Button>
                </Stack>
            </Box>

            {/* Dialog de Confirmation */}
            <Dialog
                open={openConfirm}
                onClose={handleCancel}
            >
                <DialogTitle>Confirmer la création de la tâche</DialogTitle>
                <DialogContent>
                    <Typography>Êtes-vous sûr de vouloir créer cette tâche avec les informations fournies ?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmSubmit} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar pour le feedback utilisateur */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default FormTache;
