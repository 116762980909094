import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import config from "../../config";
import { MdEmail, MdPerson, MdLock, MdImage } from 'react-icons/md';

const defaultClientData = {
    email: '',
    nom: '',
    avatarPath: null,
};

const ProfileSection = ({ clientData = defaultClientData, onSuccess }) => {
    const [formData, setFormData] = useState({
        email: '',
        nom: '',
        avatar: null,
        newPassword: '',
    });

    const [previewAvatar, setPreviewAvatar] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        console.log("Données clientData :", clientData);
        if (clientData) {
            setFormData({
                email: clientData.email || '',
                nom: clientData.nom || '',
            });
        }
    }, [clientData]);

    if (!clientData) {
        return <div className="flex justify-center items-center h-64"><span className="text-xl">Chargement...</span></div>;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, avatar: file });
            setPreviewAvatar(URL.createObjectURL(file));
        }
    };

    const handleSave = async () => {
        setIsSaving(true);
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('email', formData.email);
            formDataToSend.append('nom', formData.nom);
            if (formData.newPassword) {
                formDataToSend.append('newPassword', formData.newPassword);
            }
            if (formData.avatar) {
                formDataToSend.append('avatar', formData.avatar);
            }

            await axios.put(`${config.apiUrl}/api/client/profile`, formDataToSend, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            onSuccess();
            alert('Profil mis à jour avec succès');
        } catch (error) {
            console.error('Erreur lors de la mise à jour du profil', error);
            alert('Erreur lors de la mise à jour du profil');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className="p-6 bg-white rounded-lg shadow-xl max-w-4xl mx-auto mt-10 border border-gray-200">
            <button
                onClick={() => navigate('/user-dashboard')}
                className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
            >
                Retour au tableau de bord
            </button>
            <h2 className="text-3xl font-semibold mb-6 text-gray-800">Mon Profil</h2>

            <div className="flex justify-center mb-6">
                <div className="w-28 h-28 rounded-full bg-gray-200 flex items-center justify-center text-5xl font-bold overflow-hidden shadow-md">
                    {clientData.avatarPath ? (
                        <img
                            src={`https://lou-ann.fr/avatar/${clientData.avatarPath}`}
                            alt="Avatar"
                            className="w-full h-full object-cover"
                        />
                    ) : (
                        <span>{clientData.nom.charAt(0).toUpperCase()}</span>
                    )}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                        <MdPerson className="mr-2" /> Nom
                    </label>
                    <input
                        type="text"
                        name="nom"
                        value={formData.nom}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 transition duration-200"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                        <MdEmail className="mr-2" /> Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 transition duration-200"
                    />
                </div>
                <div className="col-span-1 md:col-span-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                        <MdLock className="mr-2" /> Nouveau mot de passe
                    </label>
                    <input
                        type="password"
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2 transition duration-200"
                    />
                </div>
                <div className="col-span-1 md:col-span-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                        <MdImage className="mr-2" /> Photo d'avatar
                    </label>
                    <div className="flex items-center">
                        <label className="cursor-pointer px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200 transition duration-200 shadow-sm">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="hidden"
                            />
                            Choisir un fichier
                        </label>
                        {formData.avatar && (
                            <span className="ml-3 text-gray-600">{formData.avatar.name}</span>
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-8">
                <button
                    onClick={handleSave}
                    className={`px-6 py-2 bg-green-500 text-white font-semibold rounded hover:bg-green-600 transition duration-200 ${
                        isSaving ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={isSaving}
                >
                    {isSaving ? 'Enregistrement...' : 'Enregistrer'}
                </button>
            </div>
        </div>
    );
};

ProfileSection.propTypes = {
    clientData: PropTypes.shape({
        email: PropTypes.string.isRequired,
        nom: PropTypes.string.isRequired,
        avatarPath: PropTypes.string,
    }),
    onSuccess: PropTypes.func.isRequired,
};

export default ProfileSection;
